// logic adapted from
// freak4pc/IsraeliID.Validator.js
// https://gist.github.com/freak4pc/6802be89d019bca57756a675d761c5a8
export default {
  $validator: (value) => {
    let id = String(value).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) {
      return false;
    }

    // pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    // safty digit checksum check
    return (
      Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) %
        10 ===
      0
    );
  },
};
