import throttleAsyncFunction from "@/client/utilities/throttleAsyncFunction.js";
import getAsyncOps from "@/client/extensions/composition/asyncOperations.js";
import { helpers } from "@vuelidate/validators";
import { computed } from "vue";

let getSafeOptions = (options) => {
  let result = {};
  if (options && typeof options === "object") {
    result = { ...result, ...options };
  }

  // target url is mandatory
  if (!result.paramName) {
    result.paramName = "value";
  }

  if (!result.url) {
    debug("availableOnRemote validator - no url provided. expect an error", 2);
  }

  return result;
};

export default function (options = {}) {
  let store = options.store ? options.store : false;

  // computed allows to change the options reactivly
  let finalOptions = computed(() => getSafeOptions(options));

  let validator = (value) => {
    let runValidator = async (resolveCb, rejectCb) => {
      let data = { [finalOptions.value.paramName]: value };

      if (finalOptions.value.hasOwnProperty("exclude")) {
        data.exclude = finalOptions.value.exclude;
      }

      let requestResult = await getAsyncOps({}, store).asyncCall(
        finalOptions.value.url,
        data,
        {}
      );

      if (requestResult.code !== 200) {
        rejectCb(false);
      } else {
        resolveCb(requestResult.data);
      }
    };

    return new Promise((resolve, reject) => {
      throttleAsyncFunction(runValidator, resolve, reject, options);
    });
  };

  return helpers.withParams(
    { type: "availableOnRemote" },
    helpers.withAsync(validator)
  );
}
