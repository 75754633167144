import parallelDynamicImport from "@/client/utilities/parallelDynamicImport.js";

const appName = process.env.VUE_APP_APPLICATION_NAME;

let importDefinition = [
  {
    targetKey: "styles",
    promise: import(
      /* webpackChunkName: "app-vendors" */ "@/client/assets/index.js"
    ),
  },
  {
    targetKey: "importPlugins",
    promise: import("@/client/extensions/plugins/index.js"),
  },
  {
    targetKey: "importDirectives",
    promise: import("@/client/extensions/directives/index.js"),
  },
  { targetKey: "routerFactory", promise: import("@/client/router") },
  { targetKey: "createStore", promise: import("@/client/store") },
  {
    targetKey: "app",
    promise: import("@/client/applications/" + appName + "/components/App.vue"),
  },
];

let getApplicationParts = async () => {
  let imports = await parallelDynamicImport(importDefinition);

  return {
    app: imports.app,
    store: imports.createStore(),
    router: imports.routerFactory, // exporting router as a factory function is supported. it gets a reference to the built app, which supports a banch of stuff
    extensions: {
      plugins: await imports.importPlugins(), // await plugins - allows plugin factories to return "async plugins"
      directives: imports.importDirectives(),
    },
  };
};

let getApplication = async () => {
  return await getApplicationParts().app;
};

export { getApplicationParts, getApplication };
