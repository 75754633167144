const i18nPhoneNumber = {
  $validator: (value) => {
    let isStringNumber = () => {
      if (value === null || value === "") {
        return true;
      }

      if (typeof value !== "string") return false;

      if (!value.startsWith("+")) return false;

      if (value.startsWith("+0")) return false; // invalid intel prefix

      if (value.length != 13) return false;

      // Todo: improve this, we need to try and support prefixes shorter than 3 digits

      let isNumeric = /^\d+$/.test(value.substring(1));

      if (!isNumeric) return false;

      return true;
    };
    let isObjectNumber = () => {
      // data stracture validation
      if (typeof value !== "object" || !value) {
        return false;
      }

      if (!value.hasOwnProperty("prefix") || !value.hasOwnProperty("number")) {
        return false;
      }

      // prefix validation - string, 4 digits max and starts with +
      if (
        typeof value.prefix !== "string" ||
        !value.prefix.startsWith("+") ||
        value.prefix.length > 4
      ) {
        return false;
      }

      // number validation
      if (typeof value.number !== "string" || value.number.length !== 9) {
        return false;
      }

      // number must be numeric
      if (!/^\d+$/.test(value.number)) {
        return false;
      }

      // prefix needs to be number, apart from the leading +
      if (!/^\d+$/.test(value.prefix.slice(1))) {
        return false;
      }

      return true;
    };

    return isStringNumber() || isObjectNumber();
  },
};

export default i18nPhoneNumber;
