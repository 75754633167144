export default () => {
  let validRoomCategories = ["Inside", "Outside", "Balcony", "Suite"];
  let validTaxCategories = ["CruiseTax", "PortCharge"];
  let maxPrice = 99999999999;
  let getRoomCategoryLabel = (category) => {
    return "icruise.cruise.roomCategories." + utilities.lcFirst(category) + ".label";
  };

  let getSupplierUrl = (relativeUrl) => {
    if (!relativeUrl) {
      return "";
    }

    if (typeof relativeUrl !== "string") {
      return "";
    }

    if (relativeUrl.startsWith("/")) {
      return config.icruise.supplierRootUrl + relativeUrl;
    }
    return config.icruise.supplierRootUrl + "/" + relativeUrl;
  };

  let getMediaUrl = (relativeUrl) => {
    if (relativeUrl.startsWith("/")) {
      return config.icruise.supplierRootUrl + relativeUrl;
    }
    return config.icruise.supplierRootUrl + "/" + relativeUrl;
  };

  let getAnyCruiseShipImageUrl = (cruise, size) => {
    Object.values(cruise.ship.imagesBySize).forEach((item) => {
      if (item && item != "") {
        return getMediaUrl(item);
      }
    });
  };

  let getCruiseShipImageUrl = (cruise, size = "l") => {
    let target = cruise.ship.imagesBySize[size];
    if (target) {
      return getMediaUrl(target);
    }
    return getAnyCruiseShipImageUrl(cruise);
  };

  let getCruiselineLogoUrl = (cruise) => {
    return getMediaUrl(cruise.cruiseline.logoPath);
  };

  let getCruiselineImageUrl = (cruise) => {
    return getCruiselineLogoUrl(cruise);
  };

  let getCruisePackageLink = (id) => {
    let siid = config.icruise.supplierId;
    return `${config.icruise.supplierRootUrl}/swift/cruise/package/${id}?siid=${siid}&lang=9`;
  };

  let getLowestPrice = (cruise, withTax = false, options = { asCents: true }) => {
    let extractLowestPrice = (item, withTax) => {
      let lowest = maxPrice;
      if (!item?.prices[0]?.items) {
        return lowest;
      }
      item.prices[0].items.forEach((priceItem) => {
        if (!validRoomCategories.includes(priceItem.name)) {
          return true;
        }
        if (parseFloat(priceItem.value) < lowest) {
          lowest = priceItem.value;
        }
      });

      if (lowest === maxPrice) {
        lowest = 0;
      }
      if (withTax) {
        lowest = lowest + getTaxes(item, { asCents: false });
      }
      return lowest;
    };

    let price = maxPrice;

    if (Array.isArray(cruise.sailings) && cruise.sailings.length > 0) {
      // go over all sailings, and find the lowest price from them
      cruise.sailings.forEach((sailing) => {
        let sailingPrice = extractLowestPrice(sailing, withTax);
        if (sailingPrice < price) {
          price = sailingPrice;
        }
      });
    } else {
      // just one cruise - get it's price
      price = extractLowestPrice(cruise, withTax);
    }

    if (price == maxPrice) {
      price = 0;
    }
    if (!options || options.asCents !== false) {
      price = Math.round(price * 100);
    }

    if (price === maxPrice) {
      return 0;
    }
    return price;
  };

  let getLowestPriceCategorized = (cruise, withTax = false, options = { asCents: true }) => {
    let result = {

    }
    result.validRoomCategories
    return result;
  };

  let getLowestPricePerDay = (cruise, withTax, options = { asCents: true }) => {
    let price = getLowestPrice(cruise, withTax, { asCents: false });
    if (cruise.duration === 0) {
      //just for safety
      return price;
    }
    let dayPrice = (price / cruise.duration).toFixed(2);

    if (!options || options.asCents !== false) {
      dayPrice = Math.round(dayPrice * 100);
    }
    return dayPrice;
  };

  let hasTaxes = (cruise) => {
    return getTaxes(cruise) > 0;
  };

  let getTaxes = (cruise, options = { asCents: true }) => {
    let tax = 0;
    if (!cruise?.prices[0]?.items) {
      return 0;
    }
    cruise.prices[0].items.forEach((priceItem) => {
      if (!validTaxCategories.includes(priceItem?.code)) {
        return true;
      }
      tax = Number(tax) + Number(priceItem.value);
    });

    if (!options || options.asCents !== false) {
      tax = Math.round(tax * 100);
    }
    return Number(tax);
  };

  let getRoomTypePrice = (cruise, type, options = { asCents: true }) => {
    if (!cruise?.prices[0]?.items) {
      return 0;
    }
    let result = 0;
    let targetType = utilities.ucFirst(type);

    cruise.prices[0].items.forEach((priceItem) => {
      if (!priceItem.name) {
        return true;
      }

      if (priceItem.name === targetType) {
        result = priceItem.value;
        return false;
      }
    });

    if (!options || options.asCents !== false) {
      result = Math.round(result * 100);
    }

    return result;
  };

  let hasRoomType = (cruise, type) => {
    return getRoomTypePrice(cruise, type) > 0;
  };

  let getPricingCurrencyCode = (cruise) => {
    try {
      console.log("returning1",cruise.prices[0].currencyCode.toUpperCase())
      return cruise.prices[0].currencyCode.toUpperCase();
    } catch (e) {
      return config.ecommerce.currency.default;
    }
  };

  return {
    getSupplierUrl,
    getMediaUrl,
    getCruiseShipImageUrl,
    getAnyCruiseShipImageUrl,
    getLowestPrice,
    getLowestPricePerDay,
    getTaxes,
    hasTaxes,
    getRoomTypePrice,
    hasRoomType,
    getPricingCurrencyCode,
    roomCategories: validRoomCategories,
    getRoomCategoryLabel,
    getCruiselineLogoUrl,
    getCruiselineImageUrl,
    getCruisePackageLink,
  };
};
