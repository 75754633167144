/**
 * Import dynamicly several modules at the same time using Promise.allSettled.
 *
 * @param imports - array of objects. Each must have :
 * targetKey - string - name of the module imported in the result value
 * promise   - promise - the import promise, done or pending
 *
 * Each MAY have :
 * importKey - string - which export should be taken from the imported module. Default is "default"
 *
 * TODO: support object or array in import key, for multiple imports
 * @returns {Promise<void>}
 */
let parallelDynamicImport = async (imports) => {
  let promises = [];

  let importedAssets = {};

  // enforce integrity
  imports.forEach((val, index) => {
    if (typeof val.importKey !== "string") {
      val.importKey = "default";
    }

    promises.push(val.promise);
  });

  let results = await Promise.allSettled(promises);

  results.forEach((result, index) => {
    let importKey = imports[index].importKey;
    let targetKey = imports[index].targetKey || "default";

    if (result.status === "rejected") {
      debug("Can not execute paraller import -  promise rejected", 2, {
        promise: result,
        index: index,
        imports: imports,
        import: imports[index],
      });
      return true;
    }

    imports[index].value = result.value[importKey];
    importedAssets[targetKey] = result.value[importKey];
  });

  return importedAssets;
};

export default parallelDynamicImport;
