export default async ({ asyncOps, store }) => {
  let userItems = {
    personalTitle: {
      action: {
        type: "title",
      },
      label: "userDashboard.nav.personalTitle",
    },
    // dashboard: {
    //   action: {
    //     type: "routerNavigation",
    //     data: {
    //       to: { name: "user-dashboard-dashboard" },
    //     },
    //   },
    //   icon: "dashboard",
    //   label: "userDashboard.nav.dashboard",
    // },
    profile: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-profile" },
        },
      },
      icon: "user",
      label: "userDashboard.nav.profile",
    },
    orders: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-orders" },
        },
      },
      icon: "cart",
      label: "userDashboard.nav.orders",
    },
    payments: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-payments" },
        },
      },
      icon: "credit-cards",
      label: "userDashboard.nav.payments",
    },
    reviews: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-reviews" },
        },
      },
      icon: "star",
      label: "userDashboard.nav.reviews",
    },
    notifications: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-notifications" },
        },
      },
      icon: "bell",
      label: "userDashboard.nav.notifications",
    },
    conversations: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-conversations" },
        },
      },
      icon: "chat",
      label: "userDashboard.nav.conversations",
    },
    help: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-help" },
        },
      },
      icon: "life-float",
      label: "userDashboard.nav.help",
    },
  };

  let vendorItems = {
    vendorTitle: {
      action: {
        type: "title",
      },
      label: "userDashboard.nav.vendorTitle",
    },
    // vendorDashboard: {
    //   action: {
    //     type: "routerNavigation",
    //     data: {
    //       to: { name: "user-dashboard-vendor-dashboard" },
    //     },
    //   },
    //   icon: "dashboard",
    //   label: "userDashboard.nav.vendorDashboard",
    // },
    vendorOrders: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-orders" },
        },
      },
      icon: "credit-cards",
      label: "userDashboard.nav.vendorOrders",
    },
    vendorReviews: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-reviews" },
        },
      },
      icon: "star",
      label: "userDashboard.nav.vendorReviews",
    },
    vendorProducts: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-products" },
        },
      },
      icon: "archive",
      label: "userDashboard.nav.vendorProducts",
    },
    vendorProfile: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-profile" },
        },
      },
      icon: "bag-alt",
      label: "userDashboard.nav.vendorProfile",
    },

    // reviews, products,vendor profile
  };

  let finalItems;

  if (store && store.getters["user/profile"]?.Vendor) {
    finalItems = {
      vendorItems: {
        action: false,
        icon: "bag",
        label: "userDashboard.nav.vendorItems",
        children: {
          ...vendorItems,
        },
      },
      userItems: {
        action: false,
        icon: "user",
        label: "userDashboard.nav.userItems",
        children: {
          ...userItems,
        },
      },

    };
  } else {
    finalItems = {
      ...userItems,
    };
  }

  return finalItems;
};
