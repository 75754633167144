module.exports = {
  name: "Icruise",
  iconPaths: {
    favicon32: "icruise/images/favicons/favicon-32x32.png",
    favicon16: "icruise/images/favicons/favicon-16x16.png",
    appleTouchIcon: "icruise/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "icruise/images/favicons/safari-pinned-tab.svg",
    msTileImage: "icruise/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#15628a",
};
