import { reactive, watchEffect } from "vue";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

// todo: support args for handler
const throttleAsyncFunction = (
  handler,
  fulfilCallback,
  rejectCallback,
  options = { delay: 1000 }
) => {
  let debouncers = reactive({});
  let finalOptions, identifier, delay;

  finalOptions = options;

  if (typeof options !== "object" || options === null) {
    finalOptions = {};
  }

  identifier =
    finalOptions.identifier ||
    utilities.getUniqueNumber() + "-" + getRandomInt(9999999);
  delay = finalOptions.delay || 1000;

  if (typeof debouncers[identifier] === "undefined") {
    debouncers[identifier] = {
      awaiting: false,
      timeout: null,
    };
  }

  clearTimeout(debouncers[identifier].timeout);

  debouncers[identifier].awaiting = true;

  // ssr has no reactivity, so its ok for this to just run

  debouncers[identifier].timeout = utilities.setClientTimeout(() => {
    debouncers[identifier].awaiting = false;
  }, delay);

  watchEffect(() => {
    if (debouncers[identifier].awaiting === false) {
      clearTimeout(debouncers[identifier].timeout);
      handler(fulfilCallback, rejectCallback).then(
        () => {},
        () => {}
      );
    }
  });
};

export default throttleAsyncFunction;
