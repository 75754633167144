export default {
  types: {
    article: {
      routeFunction: (item) => ({
        name: "content-article-articleAlias",
        params: { articleAlias: item.alias },
      }),
    },
    product: {
      routeFunction: (item) => ({
        name: "store-product-productId",
        params: { productId: item.id },
      }),
    },
    vendor: {
      routeFunction: (item) => ({
        name: "store-vendor-vendorId",
        params: { vendorId: item.id },
      }),
    },
  },
};
