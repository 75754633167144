export default {
  currency: {
    useConversions: false,
    default: "ILS",
    availableCurrencies: [],
  },
  productTypes: {
    activeProductTypes: [
      { value: "default", label: "ecommerce.product.types.default.label" },
    ],
    defaultProductTypes: [],
  },
  order: {
    statuses: [],
  },
};
