// logic adapted from
// freak4pc/IsraeliID.Validator.js
// https://gist.github.com/freak4pc/6802be89d019bca57756a675d761c5a8
export default {
  $validator: (value) => {
    if (value.length !== 10) {
      return false;
    }

    if (!value.startsWith(0)) {
      return false;
    }
    return /^\d+$/.test(value);
  },
};
