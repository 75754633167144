export default async ({ asyncOps, store }) => {
  return {
    dashboard: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin" },
        },
      },
      icon: "dashboard",
      label: "admin.sideNav.dashboard",
    },
    contentParent: {
      action: false,
      icon: "article",
      label: "icruise.adminNav.contentTitle",
      children: {
        homepage: {
          icon: "home",
          label: "icruise.adminNav.homepage",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "homepage" },
              },
            },
          },
        },
        shipContent: {
          icon: "cruise-list-item-ship",
          label: "icruise.adminNav.shipContent",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "shipContent" },
              },
            },
          },
        },
        cruiselineContent: {
          icon: "icruise-cruiseline",
          label: "icruise.adminNav.cruiselineContent",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "cruiselineContent" },
              },
            },
          },
        },
        destinationContent: {
          icon: "icruise-map",
          label: "icruise.adminNav.destinationContent",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "destinationContent" },
              },
            },
          },
        },
        salePages: {
          icon: "cart-plus",
          label: "icruise.adminNav.salePages",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "salePage" },
              },
            },
          },
        },
      }
    },
    cruiseParent: {
      action: false,
      icon  : "icruise-cruiseline",
      label: "icruise.adminNav.cruiseTitle",
      children: {
        cruiseDeals: {
          icon: "bag-plus",
          label: "icruise.adminNav.cruiseDeals",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "cruiseFilter" },
              },
            },
          },
        },
        cruiseShips: {
          icon: "cruise-block-ship",
          label: "icruise.adminNav.cruiseShips",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "ship" },
              },
            },
          },
        },
        cruiseCruiselines: {
          icon: "icruise-cruiseline",
          label: "icruise.adminNav.cruiseCruiselines",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "cruiseline" },
              },
            },
          },
        },
        cruiseDestinations: {
          icon: "icruise-map",
          label: "icruise.adminNav.cruiseDestinations",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "destination" },
              },
            },
          },
        },
        cruisePorts: {
          icon: "map-pin-location-alt",
          label: "icruise.adminNav.cruisePorts",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "port" },
              },
            },
          },
        },
      }
    },



    generalTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.generalTitle",
    },
    entities: {
      action: false,
      icon: "layer-stack",
      label: "admin.sideNav.entities",
      children: {
        entityDashboard: {
          action: {
            type: "routerNavigation",
            data: {
              to: { name: "entity-dashboard" },
            },
          },
          icon: "layer-stack",
          label: "admin.sideNav.entityDashboard",
        },
        category: {
          icon: "handle-horizontal",
          label: "admin.sideNav.category",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "category" },
              },
            },
          },
        },
        tag: {
          icon: "hashtag",
          label: "admin.sideNav.tag",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "tag" },
              },
            },
          },
        },
        article: {
          icon: "pencil-write",
          label: "admin.sideNav.article",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "article" },
              },
            },
          },
        },
      },
    },
    media: {
      label: "admin.sideNav.media",
      icon: "image",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "admin-media",
          },
        },
      },
    },
    userTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.userTitle",
    },
    user: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-user" },
        },
      },
      icon: "user",
      label: "admin.sideNav.user",
    },
    acl: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-acl" },
        },
      },
      icon: "lock",
      label: "admin.sideNav.acl",
    },
    settingsTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.settingsTitle",
    },
    language: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-language" },
        },
      },
      icon: "chat-message-alt",
      label: "admin.sideNav.language",
    },
    settings: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-config" },
        },
      },
      icon: "settings",
      label: "admin.sideNav.config",
    },
  };
};
