import _ from "lodash/object";
import { reactive } from "vue";

let config;
let override = false;

//let context  = require.context('@/overrides/client/config/', true, /\.js/);
let context = require.context(
  "@/",
  true,
  /\/overrides\/client\/config\/index\.js/
);

context.keys().forEach((key) => {
  if ("./overrides/client/config/index.js" === key) {
    override = context(key);
  }
});

config = require("@/client/config/config.js").default;

if (typeof override === "object") {
  config = _.merge(config, override);
}

export default reactive(config);
