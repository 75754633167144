let appName = process.env.VUE_APP_APPLICATION_NAME;
let configModules = {};
let envConfigPrefix = "VUE_APP_CONFIG_";
import _ from "lodash/object";

// here is the config hirarchy that is loaded:
// app override root > app override module > app root > app module > core root override > core module override > core root > core module

// core config modules
let context = require.context("@/client/config/modules", true, /\.js/);
let rootConfig = {};

context.keys().forEach((key) => {
  let cleanKey = key
    .replace("./modules", "")
    .replace(".js", "")
    .replace("./", "");
  configModules[cleanKey] = context(key).default;
});

// get and merge in core root config
context = require.context("@/client/config/", true, /\.js/);
context.keys().forEach((key) => {
  if (key === "./root.js") {
    rootConfig = context(key).default;
    return true;
  }
});

configModules = _.merge(rootConfig, configModules);

// core config module overrides
//context  = require.context('@/overrides/client/config/modules', true, /\.js/);

context = require.context(
  "@/",
  true,
  /\/overrides\/client\/config\/modules\/.*\.js/
);

context.keys().forEach((key) => {
  let cleanKey = key.split("/").pop().replace(".js", "").replace("./", "");
  configModules[cleanKey] = context(key).default;
});

// get and merge in core config root override
//context  = require.context('@/overrides/client/config/', true, /\.js/);
context = require.context("@/", true, /\/overrides\/client\/config\/.*\.js/);

context.keys().forEach((key) => {
  if (key === "./overrides/client/config/root.js") {
    let overrideConf = context(key).default;
    rootConfig = _.merge(rootConfig, overrideConf);
    return true;
  }
});

configModules = _.merge(rootConfig, configModules);

// application level configuration modules
context = require.context(
  "@/client/applications/",
  true,
  /^\.\/.*\/config\/modules.*\.js$/
);
context.keys().forEach((key) => {
  // filter only the modules for out application
  if (!key.startsWith("./" + appName)) {
    return;
  }

  let moduleName = key.split("/").pop().replace(".js", "");

  if (!configModules.hasOwnProperty(moduleName)) {
    configModules[moduleName] = {};
  }

  let newConfig = context(key).default;
  configModules[moduleName] = _.merge(configModules[moduleName], newConfig);
});

// app level root config
context = require.context(
  "@/client/applications/",
  true,
  /^\.\/.*\/config\/root\.js$/
);
context.keys().forEach((key) => {
  // filter only the modules for out application
  if (!key.startsWith("./" + appName)) {
    return;
  }

  let newConfig = context(key).default;
  configModules = _.merge(rootConfig, newConfig);
});

// application config module overrides
//context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/config\/modules.*\.js$/);
context = require.context(
  "@/",
  true,
  /overrides\/client\/applications\/.*\/config\/modules.*\.js/
);

context.keys().forEach((key) => {
  // filter only the modules for out application
  if (!key.startsWith("./overrides/client/applications/" + appName)) {
    return;
  }

  let moduleName = key.split("/").pop().replace(".js", "");

  if (!configModules.hasOwnProperty(moduleName)) {
    configModules[moduleName] = {};
  }

  let newConfig = context(key).default;
  configModules[moduleName] = _.merge(configModules[moduleName], newConfig);
});

//  application config root overrides
//context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/config\/root\.js$/);
context = require.context(
  "@/",
  true,
  /overrides\/client\/applications\/.*\/config\/root\.js/
);

context.keys().forEach((key) => {
  // filter only the modules for out application
  if (!key.startsWith("./overrides/client/applications/" + appName)) {
    return;
  }

  let newConfig = context(key).default;
  configModules = _.merge(rootConfig, newConfig);
});

// env override - helper functions
const assignObjectPropertyRecursive = (path, val, rel = configModules) => {
  let exploded = path.split("."),
    next = exploded.shift();

  next = next.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  }); // to camel
  if (exploded.length === 0) {
    rel[next] = val;
    return true;
  }

  // ensure next level is object
  if (typeof rel[next] !== "object" || rel[next] === null) {
    rel[next] = {};
  }

  return assignObjectPropertyRecursive(exploded.join("."), val, rel[next]);
};

const getTypedOverrideValue = (override) => {
  if (override["type"] === "number") {
    return Number(override["val"]);
  }

  if (override["type"] === "integer") {
    return parseInt(override["val"]);
  }

  if (override["type"] === "boolean") {
    // env values always come as strings. no need to check condition for bool or int
    return override["val"] === "true" || override["val"] === "1";
  }

  return override["val"];
};

// implement env override
let rawEnvOverrides =
  process.env && typeof process.env === "object" ? process.env : {};

for (const [key, value] of Object.entries(rawEnvOverrides)) {
  if (!key.startsWith(envConfigPrefix)) {
    continue;
  }

  let exploded = key.replace(envConfigPrefix, "").split("___");
  let type = exploded[1] ? exploded[1].toLowerCase() : "any";
  let finalKey = exploded[0].replace(/__/g, "-").toLowerCase();
  finalKey = finalKey.replace(/_/g, ".").toLowerCase();

  assignObjectPropertyRecursive(
    finalKey,
    getTypedOverrideValue({ val: value, type: type })
  );
}

export default configModules;
