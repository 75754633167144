export default {
  saffronUser: {
    getCurrentUserUrl: "user/current",
    getRefreshTokenUrl: "token/refresh",
    getJwtBaseUrl: "token/jwt",
    defaultLogoutRedirect: { name: "index" },
  },
  guestLoginRedirect: { name: "login" },
  loginRedirect: { name: "index" },
};
