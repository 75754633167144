let messages = {
  sg: {
    tabs: {
      margins: "margins",
      forms: "forms",
      colors: "colors",
      typography: "typography",
      buttons: "buttons",
      badges: "badges",
      spinners: "spinners",
      icons: "icons",
      cards: "cards",
      panels: "panels",
      modals: "modals",
      alerts: "alerts",
      breadcrumbs: "breadcrumbs",
      notifications: "notifications",
      pagination: "pagination",
      tables: "tables",
      tooltips: "tooltips",
      containers: "containers",
    },
    colorGroups: {
      base: "base",
      complimentary: "complimentary",
      "gray-scale": "gray scale",
      ui: "ui",
    },
    margins: {
      width: "width",
      xs: "minimal",
      s: "small",
      m: "medium",
      l: "large",
      xl: "extra large",
      "2xl": "2xl",
      max: "max",
    },
    radius: {
      title: "radial border (corners)",
      radius: "radius",
      s: "small",
      m: "medium",
      l: "large",
      round: "round",
    },
    containers: {
      title: "containers",
      explain:
        "Standard width containers, used to align content. Always has a safety margin on the sides. Nexted containers collapse their margins. In this example, the margins are highlighted. This content is aligned to the buttons on top and the large container, since it is nested within an invisible large container.",
      container: "container",
      xs: "extra small",
      s: "small",
      m: "medium",
      l: "large",
      gutter: "gutter",
    },
  },
};

export default messages;
