import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";

export default [
  {
    path: "/:pathMatch(.*)*",
    component: asyncFactory("applications/icruise/views/PageNotFound.vue"),
    meta: {
      layout: "boxy",
      layoutParams: {
        backgroundImage: false
      },
    },
  },
];
