export default {
  supplierRootUrl: "https://book.icruise.co.il",
  supplierId: '',
  cruiseSearch: {
    searchResultsRouteName: "cruise",
    priceRanges: [
      {minimum: 1, maximum: 500},
      {minimum: 501, maximum: 1000},
      {minimum: 1001, maximum: 1500},
      {minimum: 1501, maximum: 2000},
      {minimum: 2000, maximum: 10000},
    ]
  }
};
