/**
 * When a route meta has requiresGuest, will redirect logged in users based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresGuestRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresGuestRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "saffronBus",
    handler: async (to, from, context) => {
      // todo: user type support

      let spinnerTimeout = utilities.setClientTimeout(() => {
        router.app.store.commit("ui/showGlobalSpinner");
      }, 300);
      // wait for user. no more than 2 seconds
      await utilities.waitForUser(router.app.store);
      /*
       new Promise((resolve) => {

       watchEffect( () => {
       let resolveTimeout = setTimeout(() => {
       clearTimeout(resolveTimeout);
       resolve();
       }, 5000);
       if (router.app.store.getters['user/initialFetchComplete']) {
       clearTimeout(resolveTimeout);
       resolve();
       }
       });
       });
       */

      clearTimeout(spinnerTimeout);
      router.app.store.commit("ui/hideGlobalSpinner");

      if (router.app.store.getters["user/isAuthenticated"]) {
        return true;
      }

      router.push({ name: "auth", params: { loginRedirect: to.fullPath } });
      return false;
    },
  };
};
