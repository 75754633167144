export default {
  tag: "en-US",
  label: "EN",
  labelFull: "English (United States APP OVERRIDE)",
  flag: "united-kingdom", // todo: get and change this to US
  messages: {
    message: {},
  },
  datetimeFormats: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    },
  },
  numberFormats: {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  useTitleCase: true,
  translateNumber(instance, value, type, local, options) {
    if (type === "phone") {
      if (typeof value !== "string") {
        return "";
      }
      let prefix = value.slice(0, 4);
      let num = value.slice(4);
      let numA = num.slice(0, 3);
      let numB = num.slice(3, 6);
      let numC = num.slice(6, 9);

      return `(${prefix}) ${numA}-${numB}-${numC}`;
    }
    return instance.$n(value, type, local, options);
  },
};
