export default () => {
  let fields = {
    firstName: {
      name: "firstName",
      type: "text",
      label: "icruise.forms.basicContact.firstNamePlaceholder",
      placeholder: "icruise.forms.basicContact.firstNamePlaceholder",
      validation: {},
      group: "mainFields",
    },
    lastName: {
      name: "lastName",
      type: "text",
      label: "icruise.forms.basicContact.lastNamePlaceholder",
      placeholder: "icruise.forms.basicContact.lastNamePlaceholder",
      validation: {},
      group: "mainFields",
    },
    phoneNumber: {
      name: "phoneNumber",
      type: "text",
      label: "icruise.forms.basicContact.phoneNumberPlaceholder",
      placeholder: "icruise.forms.basicContact.phoneNumberPlaceholder",
      validation: {},
      group: "mainFields",
    },
    email: {
      name: "email",
      type: "email",
      label: "icruise.forms.basicContact.emailPlaceholder",
      placeholder: "icruise.forms.basicContact.emailPlaceholder",
      validation: {
        required: {},
        email: {},
      },
      group: "mainFields",
    },
    content: {
      name: "content",
      type: "textarea",
      class: "content-field",
      label: "icruise.forms.basicContact.contentPlaceholder",
      placeholder: "icruise.forms.basicContact.contentPlaceholder",
      validation: {
        required: {},
      },
      group: "contentFieldGroup",
    },
  };

  let groups = {
    mainFields: {
      component: "formFieldSet",
      props: {
        class: "main-fields-group",
        maxFieldsPerRow: 2,
      },
    },
    contentFieldGroup: {
      component: "formFieldSet",
      props: {
        class: "main-fields-group",
        maxFieldsPerRow: 1,
      },
    },
  };

  let buttons = {
    submit: {
      type: "submit",
      text: "icruise.forms.basicContact.submitCtaText",
      class: "submit-button  margin-auto-inline-start min-width-200",
    },
  };

  return { fields, groups, buttons };
};
