const i18nPhoneNumber = {
  $validator: (value) => {
    if (value === null || value === "") {
      return true;
    }

    if (typeof value !== "string") return false;

    if (!value.startsWith("+")) return false;

    if (value.startsWith("+0")) return false; // invalid intel prefix

    if (value.length != 13) return false;

    // Todo: improve this, we need to try and support prefixes shorter than 3 digits

    let isNumeric = /^\d+$/.test(value.substring(1));

    if (!isNumeric) return false;

    return true;
  },
};

export default i18nPhoneNumber;
