export default {
  frameworks: ["we"],
  frameworkComponents: ["we"],
  scss: ["styles"],
  responsive: {
    classMap: {
      // please define in app config. otherwise its comulative
      //  xsmall  : 400,
      //     small   : 640,
      //  medium  : 960,
      //   large   : 1200,
      //   xlarge  : 1600,
    },
    responsiveClassPrefix: "break-",
    responsiveClassMode: "mobileFirst",
  },
  defaultIconSet: ["rubicons"],
  defaultSpinnerType: "circle",
  dropdown: {
    animationClass: "animate__fadeInUpSmall animate--fastest",
    gap: 10,
  },
  weUI: {
    buttonThemes: {
      lead: { value: "lead", color1: "lead", color2: "bg" },
      "lead-inverse": { value: "lead-inverse", color1: "bg", color2: "lead" },
      "lead-2": { value: "lead-2", color1: "lead-2", color2: "bg" },
      alt: { value: "alt", color1: "alt", color2: "bg" },
      "alt-inverse": { value: "alt-inverse", color1: "bg", color2: "alt" },
      "alt-2": { value: "alt-2", color1: "alt-2", color2: "bg" },
      inverse: { value: "inverse", color1: "inverse", color2: "bg" },
      bg: { value: "bg", color1: "bg", color2: "inverse" },
      "gray-2": { value: "gray-2", color1: "gray-2", color2: "bg" },
      gray: { value: "gray", color1: "gray", color2: "bg" },
      "gray-4": { value: "gray-4", color1: "gray-4", color2: "bg" },
      "gray-5": { value: "gray-5", color1: "gray-5", color2: "bg" },
      "comp-lead": { value: "comp-lead", color1: "comp-lead", color2: "bg" },
      "comp-lead-2": { value: "comp-lead-2", color1: "comp-lead-2", color2: "bg" },
      "comp-alt": { value: "comp-alt", color1: "comp-alt", color2: "bg" },
      "comp-alt-2": { value: "comp-alt-2", color1: "comp-alt-2", color2: "bg" },
      success: { value: "success", color1: "success", color2: "bg" },
      "bg-transparent": { value: "bg-transparent", color1: "bg", color2: "inverse" },
      warning: { value: "warning", color1: "warning", color2: "bg" },
      danger: { value: "danger", color1: "danger", color2: "bg" },
      error: { value: "error", color1: "error", color2: "bg" },
    },
    themes: [
      "lead-1",
      "lead-2",
      "lead",
      "lead-4",
      "lead-5",
      "alt-1",
      "alt-2",
      "alt",
      "alt-4",
      "alt-5",
      "bg-1",
      "bg-2",
      "bg",
      "bg-4",
      "bg-5",
      "gray-1",
      "gray-2",
      "gray",
      "gray-4",
      "gray-5",
      "inverse",
      "text",
      "success",
      "warning",
      "error",
    ],
    notification: {
      defaultAnimation: "fadeInUpSmall",
      defaultCloseAnimation: "fadeOut",
      defaultDuration: 5000,
      defaultPosition: "notifications",
    },
    button: {
      defaultTheme: "primary",
    },
    tooltip: {
      animationClassIn: "animate__fadeIn",
      animationClassOut: "animate__fadeOut",
      extraClass: "animate--faster",
    },
  },
  pageTransitionClassIn: "animate__fadeIn animate--fast",
  pageTransitionClassOut: "animate__fadeOut  animate--faster",
};
